//================================= LIGHTBOX

$("a.venobox, .gallery a, .module-slideshow a").venobox({
	numeratio:    true,
	spinner:      'double-bounce',
	overlayColor: 'rgba(255,255,255,0.85)',
	//framewidth: '95%'
});

/*$("a.venobox-video").attr('data-vbtype', 'video').venobox({
	autoplay: true,
	spinner: 'double-bounce'
});*/
