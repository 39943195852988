//================================= SWIPER

let labCtaFeaturedPagesSwiper = new Swiper('.lab-cta .swiper-container', {
	slidesPerView: 'auto',
	spaceBetween: 24,
	pagination: {
		el: '.lab-cta .swiper-pagination',
		type: 'custom',
		renderCustom: function (swiper, current, total) {
			return '<span class="swiper-pagination-current">' + current + '</span> <span class="swiper-pagination-separator"></span> <span class="swiper-pagination-total">' + total + '</span>';
		}
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	breakpoints: {
		// when window width is >= 992px
		992: {
			spaceBetween: 16
		}
	}
});

let slideshowSwiper = new Swiper('.slideshow .swiper-container', {
	slidesPerView: 'auto',
	spaceBetween: 24,
	pagination: {
		el: '.slideshow .swiper-pagination',
		type: 'custom',
		renderCustom: function (swiper, current, total) {
			return '<span class="swiper-pagination-current">' + current + '</span> <span class="swiper-pagination-separator"></span> <span class="swiper-pagination-total">' + total + '</span>';
		}
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	breakpoints: {
		// when window width is >= 992px
		992: {
			spaceBetween: 16
		}
	}
});


$('.gallery-display-as-slideshow').each(function () {
	let gallerySlideshowSwiper = new Swiper($(this).find('.swiper-container')[0], {
		slidesPerView: 'auto',
		speed:         500,
		/*pagination:    {
			el: $(this).find('.swiper-pagination')[0],
			clickable: true
		},
		loop:          true,
		autoplay:      {
			delay:                3000,
			stopOnLastSlide:      false
		},*/
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
	});

	gallerySlideshowSwiper.el.addEventListener('mouseenter', function( event ) {
		gallerySlideshowSwiper.autoplay.stop();
	}, false);

	gallerySlideshowSwiper.el.addEventListener('mouseleave', function( event ) {
		gallerySlideshowSwiper.autoplay.start();
	}, false);
});