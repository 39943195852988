const _window = $(window);
const body = $('body');

//================================= CHILD PAGES MENU

let childPagesMenu = $('.child-pages-menu');
$('.child-pages-menu__toggle').on('click', function () {
	childPagesMenu.toggleClass('child-pages-menu--open');
})


function setHeightToInnovationTableCol4() {
	const item = $('.innovation-flow-table__sector--4');

	if (_window.width() < 992)
	{
		item.outerWidth(item.parent().height());
	}
	else
	{
		item.removeAttr('style');
	}
}


// play video when clicking on cover

$('.section-video').each(function () {

	if ($(this).find('iframe').length === 1)
	{
		$('.section-video__cover', $(this)).on('click', function (e) {

			$(this).fadeOut(1200);

			$(".section-video iframe")[0].src += "&autoplay=1";
			e.preventDefault();

		}).css('cursor', 'pointer');
	}
});




/**
 * ON LOAD
 */

_window.on('load', function(event) {

	setHeightToInnovationTableCol4();

});


/**
 * ON RESIZE
 */

_window.on('resize', function(event) {

	if(event.target === window) {
		// trigger resizeEnd event only when the window resizing ends
		if(this.resizeTO) clearTimeout(this.resizeTO);
		this.resizeTO = setTimeout(function() {
			$(this).trigger('resizeEnd');
		}, 200);
	}

} );


/**
 * ON RESIZE END
 */

// do something when the window resizing ends
_window.on('resizeEnd', function() {

	setHeightToInnovationTableCol4();

});

/**
 * ON SCROLL
 */
/*window.addEventListener('scroll', function() {
});*/